<template>
    <Button 
      icon="pi pi-file-excel" 
      label="CSV" 
      @click="exportCSV" 
      class="p-button-success export-button"
    />
  </template>
  
  <script setup>
  import { defineProps, defineEmits } from 'vue';
  
  defineProps({
    data: {
      type: Array,
      required: true
    }
  });
  
  const emit = defineEmits(['export']);
  
  const exportCSV = () => {
    emit('export');
  };
  </script>
  
  <style scoped>
  .export-button {
    margin-left: 1rem;
  }
  </style>