<template>
    <div class="attendees-container">
      <h2 class="attendees-title">Lista de Asistentes</h2>
      <div class="card attendees-card">
        <DataTable ref="dt" v-model:filters="filters" v-model:selection="selectedAttendee" :value="attendees"
               stateStorage="session" stateKey="attendees-table" :paginator="true" 
               :rows="rows" :rowsPerPageOptions="[5, 10, 20, 50]"
               filterDisplay="menu" selectionMode="single" dataKey="id" 
               :globalFilterFields="['eventCode', 'eventName', 'referrerCode', 'docId', 'name', 'email', 'city', 'cellPhone', 'billingDocType', 'billingDoc', 'billingName', 'billingEmail', 'billingCity', 'billingCellPhone', 'billingPhone', 'billingAddress', 'billingContact', 'observations']"
               >
          <template #header>
            <AttendeeTableHeader 
              :columns="columns" 
              :filters="filters"
              :data="attendees"
              v-model:visibleColumns="visibleColumns"
              @export="exportCSV"
            />
          </template>
          
          <!-- Columna para eliminar -->
          <Column header="Acciones" :exportable="false" style="min-width:8rem">
            <template #body="slotProps">
                <Button icon="pi pi-trash" 
                        class="p-button-rounded p-button-danger" 
                        @click="confirmDelete($event, slotProps.data)" />
            </template>
          </Column>
          
          <Column v-for="col in visibleColumns" :key="col.field" 
                  :field="col.field" :header="col.header" sortable :style="col.style">
            <template #filter="{ filterModel }" v-if="col.field !== 'acceptPolicies'">
              <InputText v-model="filterModel.value" type="text" :placeholder="`Buscar por ${col.header.toLowerCase()}`" class="p-column-filter" />
            </template>
            <template #body="{ data }" v-if="['acceptPolicies', 'selfRetainerIVA', 'selfRetainerREN', 'selfRetainerICA', 'bigContributor'].includes(col.field)">
                <Tag :value="data[col.field] ? 'Sí' : 'No'" :severity="data[col.field] ? 'success' : 'danger'" />
            </template>
            <template #filter="{ filterModel }" v-if="col.field === 'acceptPolicies'">
              <Dropdown v-model="filterModel.value" :options="['Sí', 'No']" placeholder="Seleccionar" class="p-column-filter" style="min-width: 12rem">
                <template #option="slotProps">
                  <Tag :value="slotProps.option" :severity="slotProps.option === 'Sí' ? 'success' : 'danger'" />
                </template>
              </Dropdown>
            </template>
          </Column>
          
          <template #empty> No se encontraron asistentes. </template>
        </DataTable>
      </div>
    </div>
    <ConfirmPopup />
    
  </template>
  
<script setup>
import { ref, onMounted, watch } from 'vue';
import { getFirestore, collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import AttendeeTableHeader from './AttendeeTableHeader.vue';
import ConfirmPopup from 'primevue/confirmpopup';
//import Toast from 'primevue/toast';

const dt = ref(); // Referencia al DataTable
const attendees = ref([]);
const selectedAttendee = ref();
const confirm = useConfirm();
const toast = useToast();
const rows = ref(10);
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  eventCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  eventName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  referrerCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  docId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  city: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  cellPhone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingDocType: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingDoc: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingEmail: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingCity: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingCellPhone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingPhone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingAddress: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingContact: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  acceptPolicies: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  selfRetainerIVA: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  selfRetainerREN: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  selfRetainerICA: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  bigContributor: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  observations: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
  createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  updatedAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
});

const columns = ref([
  { field: 'eventCode', header: 'C.Costos', style: 'width: 150px' },
  { field: 'eventName', header: 'Evento', style: 'width: 200px' },
  { field: 'referrerCode', header: 'Aliado', style: 'width: 150px' },
  { field: 'docId', header: 'Documento', style: 'width: 150px' },
  { field: 'name', header: 'Nombre', style: 'width: 200px' },
  { field: 'email', header: 'Email', style: 'width: 200px' },
  { field: 'city', header: 'Ciudad', style: 'width: 150px' },
  { field: 'cellPhone', header: 'Celular', style: 'width: 150px' },
  { field: 'billingDocType', header: 'CC-NIT', style: 'width: 150px' },
  { field: 'billingDoc', header: 'F.Documento', style: 'width: 150px' },
  { field: 'billingName', header: 'F.Nombre', style: 'width: 200px' },
  { field: 'billingEmail', header: 'F.Email', style: 'width: 200px' },
  { field: 'billingCity', header: 'F.Ciudad', style: 'width: 150px' },
  { field: 'billingCellPhone', header: 'F.Celular', style: 'width: 150px' },
  { field: 'billingPhone', header: 'F.Teléfono', style: 'width: 150px' },
  { field: 'billingAddress', header: 'F.Dirección', style: 'width: 200px' },
  { field: 'billingContact', header: 'F.Contacto', style: 'width: 200px' },
  { field: 'acceptPolicies', header: 'Aceptó Políticas', style: 'width: 150px' },
  { field: 'selfRetainerIVA', header: 'Auto IVA', style: 'width: 150px' },
  { field: 'selfRetainerREN', header: 'Auto REN', style: 'width: 150px' },
  { field: 'selfRetainerICA', header: 'Auto ICA', style: 'width: 150px' },
  { field: 'bigContributor', header: 'Gran Contribuyente', style: 'width: 150px' },
  { field: 'observations', header: 'Observaciones', style: 'width: 200px' },
  { field: 'createdAt', header: 'Fecha Registro', style: 'width: 150px' },
  { field: 'updatedAt', header: 'Actualización', style: 'width: 150px' }
]);

const visibleColumns = ref(columns.value);

const confirmDelete = (event, attendee) => {
  confirm.require({
    target: event.currentTarget,
    message: '¿Estás seguro de que quieres eliminar este asistente?',
    icon: 'pi pi-exclamation-triangle',
    accept: () => deleteAttendee(attendee),
    reject: () => {
      toast.add({ severity: 'info', summary: 'Cancelado', detail: 'Eliminación cancelada', life: 3000 });
    }
  });
};

const deleteAttendee = async (attendee) => {
  try {
    const db = getFirestore();
    await deleteDoc(doc(db, 'attendees', attendee.id));
    attendees.value = attendees.value.filter(a => a.id !== attendee.id);
    toast.add({ severity: 'success', summary: 'Éxito', detail: 'Asistente eliminado', life: 3000 });
  } catch (error) {
    console.error("Error al eliminar el asistente:", error);
    toast.add({ severity: 'error', summary: 'Error', detail: 'No se pudo eliminar el asistente', life: 3000 });
  }
};

onMounted(async () => {
  const db = getFirestore();
  const querySnapshot = await getDocs(collection(db, 'attendees'));
  attendees.value = querySnapshot.docs.map(doc => {
    const data = doc.data();
    return {
      id: doc.id,
      eventCode: data.eventCode,
      eventName: data.eventName,
      referrerCode: data.referrerCode,
      docId: data.docId,
      name: data.name,
      email: data.email,
      city: data.city,
      cellPhone: data.cellPhone,
      billingDocType: data.billingDocType,
      billingDoc: data.billingDoc,
      billingName: data.billingName,
      billingEmail: data.billingEmail,
      billingCity: data.billingCity,
      billingCellPhone: data.billingCellPhone,
      billingPhone: data.billingPhone,
      billingAddress: data.billingAddress,
      billingContact: data.billingContact,
      acceptPolicies: data.acceptPolicies,
      selfRetainerIVA: data.selfRetainerIVA,
      selfRetainerREN: data.selfRetainerREN,
      selfRetainerICA: data.selfRetainerICA,
      bigContributor: data.bigContributor,
      observations: data.observations,
      createdAt: data.createdAt ? new Date(data.createdAt.seconds * 1000).toLocaleString() : '',
      updatedAt: data.updatedAt ? new Date(data.updatedAt.seconds * 1000).toLocaleString() : ''
    };
  });
});

watch(rows, (newValue) => {
  console.log('Rows cambiado a:', newValue);
});

const exportCSV = () => {
  dt.value.exportCSV();
};
</script>
  
  <style scoped>
  .card {
    background: #ffffff;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12);
    margin-bottom: 2rem;
  }
  
  :deep(.p-datatable .p-datatable-header) {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem;
    font-weight: 600;
  }
  
  :deep(.p-datatable .p-datatable-thead > tr > th) {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem;
    font-weight: 600;
  }
  
  :deep(.p-datatable .p-datatable-tbody > tr) {
    background: #ffffff;
    transition: box-shadow 0.2s;
  }
  
  :deep(.p-datatable .p-datatable-tbody > tr > td) {
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem;
  }
  </style>