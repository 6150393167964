import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'


// Determina el entorno actual
const env = process.env.VUE_APP_ENV || 'dev';

// Importa la configuración de Firebase adecuada
const firebaseConfig = require(`./config/firebase.${env}.js`).default;

//Produccion

// const firebaseConfig = {
//     apiKey: "AIzaSyAr5HfDzAhN9mdk0GBfyPEZnpgJZg8OKOo",
//     authDomain: "icef-inscripciones.firebaseapp.com",
//     projectId: "icef-inscripciones",
//     storageBucket: "icef-inscripciones.appspot.com",
//     messagingSenderId: "703559640374",
//     appId: "1:703559640374:web:7bf5727e68f84bbdb97f97"
// };

//Dev

// const firebaseConfig = {
//     apiKey: "AIzaSyAzb6LYkkJfdARlxbki__aVS3EE2p36cnk",
//     authDomain: "icef-inscripciones-test.firebaseapp.com",
//     projectId: "icef-inscripciones-test",
//     storageBucket: "icef-inscripciones-test.appspot.com",
//     messagingSenderId: "120175485411",
//     appId: "1:120175485411:web:cdd7c15a2be7e7bf88c0d6"
// };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };