<template>
    <div class="flex flex-column sm:flex-row justify-content-between align-items-center">
      <MultiSelect v-model="visibleColumns" :options="columns" optionLabel="header" 
            placeholder="Seleccionar columnas" class="column-toggle custom-multiselect-large" display="chip" />
      <div class="search-export-container">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" placeholder="Buscar..." class="search-input" />
        </span>
        <ExportCSVButton :data="data" @export="$emit('export')" />
      </div>
    </div>
  </template>
  
  <script setup>
import { ref, watch } from 'vue';
import ExportCSVButton from './ExportCSVButton.vue';

const props = defineProps({
  columns: {
    type: Array,
    required: true
  },
  filters: {
    type: Object,
    required: true
  },
  data: {
    type: Array,
    required: true
  }
});

const visibleColumns = ref(props.columns);

const emit = defineEmits(['update:visibleColumns', 'export']);
watch(visibleColumns, (newValue) => {
  emit('update:visibleColumns', newValue);
});
</script>
  
  <style scoped>
  .flex {
    display: flex;
  }
  
  .flex-column {
    flex-direction: column;
  }
  
  .justify-content-between {
    justify-content: space-between;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .w-full {
    width: 100%;
  }
  
  .p-input-icon-left {
    position: relative;
    display: inline-block;
  }
  
  .p-input-icon-left > i {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #6c757d;
  }
  
  .p-input-icon-left > input {
    padding-left: 2.5rem;
  }
  
  :deep(.p-inputtext) {
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  
  :deep(.p-inputtext:enabled:hover) {
    border-color: #3B82F6;
  }
  
  :deep(.p-inputtext:enabled:focus) {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
    border-color: #3B82F6;
  }
  
  :deep(.p-multiselect) {
    min-width: 15rem;
    max-width: 35rem; /* Ajusta este valor caja de chip */
  }
  
  :deep(.p-multiselect-label) {
    padding: 0.5rem 0.75rem;
  }
  
  :deep(.p-multiselect-token) {
    padding: 0.15rem 0.5rem;
    margin-right: 0.5rem;
    background: #EEF2FF;
    color: #4F46E5;
    border-radius: 12px;
  }
  
  :deep(.p-multiselect-token-icon) {
    margin-left: 0.5rem;
  }
  
  :deep(.p-multiselect:not(.p-disabled).p-focus) {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
    border-color: #3B82F6;
  }
  
  :deep(.custom-multiselect) {
    min-width: 20rem;
  }
  
  :deep(.custom-multiselect .p-multiselect-label-container) {
    height: auto;
    min-height: 3rem;
    max-height: 6rem;
    overflow-y: auto;
  }
  
  :deep(.custom-multiselect .p-multiselect-label) {
    white-space: normal;
    overflow: visible;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.25rem;
  }
  
  :deep(.custom-multiselect .p-multiselect-token) {
    margin: 0.25rem;
  }
  
  @media (min-width: 640px) {
    .sm\:flex-row {
      flex-direction: row;
    }
  
    .sm\:w-20rem {
      width: 20rem;
    }
  
    .sm\:w-30rem {
      width: 30rem !important;
    }
  }

  
  </style>