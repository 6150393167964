
// Archivo: src/utils/dateUtils.js (o el path que prefieras)

export const formatDateTime = (timestamp) => {
  // Manejo de diferentes tipos de entrada
  let date;
  if (timestamp && timestamp.toDate) {
    // Es un Timestamp de Firestore
    date = timestamp.toDate();
  } else if (timestamp instanceof Date) {
    // Es un objeto Date
    date = timestamp;
  } else if (typeof timestamp === 'number' || typeof timestamp === 'string') {
    // Es un número (milisegundos desde la época) o una cadena de fecha
    date = new Date(timestamp);
  } else {
    return 'Fecha no disponible';
  }

  // Verificar si la fecha es válida
  if (isNaN(date.getTime())) {
    return 'Fecha inválida';
  }

  // Opciones de formato
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    //second: '2-digit',
    hour12: true,
    timeZone: 'America/Bogota' // Asegura que se use la zona horaria de Colombia
  };
  try {
    // Intentar formatear la fecha
    return date.toLocaleString('es-CO', options);
  } catch (error) {
    console.error('Error al formatear la fecha:', error);
    // Fallback a un formato básico en caso de error
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }
};

// Puedes agregar más funciones relacionadas con fechas aquí si lo necesitas