<template>
    <div class="event-list-container">
      <h2>Eventos Publicados</h2>
      <div class="event-grid">
        <div v-for="event in publishedEvents" :key="event.id" class="event-card">
          <h3>{{ event.name }}</h3>
          <p><strong>Modalidad:</strong> {{ event.modality }}</p>
          <p><strong>Fecha y Hora:</strong> {{ formatDateTime(event.dateTime) }}</p>
          <Button @click="viewDetails(event.id)" label="Ver detalles" class="p-button-secondary" />
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { collection, getDocs, query, where } from 'firebase/firestore';
  import { db } from '@/firebase';
  import { formatDateTime } from '@/utils/dateUtils';
  import Button from 'primevue/button';
  
  const router = useRouter();
  const events = ref([]);
  
  const publishedEvents = computed(() => {
    return events.value.filter(event => event.visible);
  });
  
  const fetchEvents = async () => {
    try {
      const q = query(collection(db, "events"), where("visible", "==", true));
      const querySnapshot = await getDocs(q);
      events.value = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error("Error fetching events: ", error);
    }
  };
  
  const viewDetails = (eventId) => {
    router.push({ name: 'EventDetails', params: { id: eventId } });
  };
  
  onMounted(() => {
    fetchEvents();
  });
  </script>
  
  <style scoped>
  .event-list-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .event-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .event-card {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h3 {
    margin-top: 0;
  }
  
  @media (max-width: 768px) {
    .event-grid {
      grid-template-columns: 1fr;
    }
  }
  </style>