<template>
    <div>
      <h2>Login</h2>
      <form @submit.prevent="login" class="container">
        <div class="form-group">
          <label for="email" class="form-label">Email</label>
          <input v-model="email" type="email" id="email" class="form-control" placeholder="Email" required>
        </div>
        <div class="form-group">
          <label for="password" class="form-label">Contraseña</label>
          <input v-model="password" type="password" id="password" class="form-control" placeholder="Contraseña" required>
        </div>
        <button type="submit" class="btn btn-primary btn-block">Iniciar sesión</button>
      </form>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useToast } from "primevue/usetoast"
  import { auth } from '@/firebase'
  import { signInWithEmailAndPassword } from 'firebase/auth'
  import InputText from 'primevue/inputtext'
  import Password from 'primevue/password'
  import Button from 'primevue/button'
  
  export default {
    components: {
      InputText,
      Password,
      Button
    },
    setup() {
      const email = ref('')
      const password = ref('')
      const router = useRouter()
      const toast = useToast()
  
      const login = async () => {
        try {
          await signInWithEmailAndPassword(auth, email.value, password.value)
          toast.add({severity:'success', summary: 'Éxito', detail:'Inicio de sesión exitoso', life: 3000})
          router.push('/asistentes')
        } catch (error) {
          console.error('Error de autenticación:', error)
          let errorMessage = 'Error de autenticación'
          switch (error.code) {
            case 'auth/user-not-found':
              errorMessage = 'Usuario no encontrado'
              break
            case 'auth/wrong-password':
              errorMessage = 'Contraseña incorrecta'
              break
            case 'auth/invalid-email':
              errorMessage = 'Email inválido'
              break
            // Puedes agregar más casos según los errores que quieras manejar específicamente
          }
          toast.add({severity:'error', summary: 'Error', detail: errorMessage, life: 3000})
        }
      }
  
      return {
        email,
        password,
        login
      }
    }
  }
  </script>

  
  <style scoped>
  .container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
  }
  
  .form-control {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
  }
  
  .btn-primary {
    background-color: #345cb3;
    border: none;
    color: white;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 6px;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #4089b3;
  }
  </style>
  