import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '@/firebase'
import Login from '@/views/Login.vue'
import FormView from '@/views/FormView.vue'
import AttendeeView from '@/views/AttendeeView.vue'
import AdminAttendeeView from '@/views/AdminAttendeeView.vue'
import EventManager from '@/views/EventManager.vue'
import ReferredManager from '@/views/ReferredManager.vue'
import NotFound from '@/views/NotFound.vue'
import EventList from '@/components/EventList.vue'
import EventDetails from '@/components/EventDetails.vue'

const routes = [
  
  { path: '/login', component: Login },

  { path: '/', component: FormView },

  { 
    path: '/asistentes', 
    component: AttendeeView,
    meta: { requiresAuth: true }
  },
  { 
    path: '/superadmin', 
    component: AdminAttendeeView,
    meta: { requiresAuth: true }
  },
  { 
    path: '/eventos', 
    component: EventManager,
    meta: { requiresAuth: true }
  },
  { 
    path: '/aliados', 
    component: ReferredManager,
    meta: { requiresAuth: true }
  },
  {
    path: '/events',
    name: 'EventList',
    component: EventList
  },
  {
    path: '/events/:id',
    name: 'EventDetails',
    component: EventDetails
  },
  { path: '/:pathMatch(.*)*', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  auth.onAuthStateChanged(user => {
    if (requiresAuth && !user) {
      next('/login')
    } else if (user && to.path === '/login') {
      next('/asistentes')
    } else {
      next()
    }
  });
})

export default router