import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getFirestore, collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { formatDateTime } from '@/utils/dateUtils';

export const useAttendeeStore = defineStore('attendees', () => {
  const attendees = ref([]);
  const db = getFirestore();
  let unsubscribe = null;
  let initialDataLoaded = false;

  const fetchAttendees = () => {
    if (unsubscribe) unsubscribe();

    const q = query(collection(db, 'attendees'), orderBy('createdAt', 'asc'));
    unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          const newAttendee = {
            id: change.doc.id,
            ...change.doc.data(),
            createdAt: formatDateTime(change.doc.data().createdAt),
            updatedAt: formatDateTime(change.doc.data().updatedAt)
          };
          
          // Verificar si el asistente ya existe en la lista
          const existingIndex = attendees.value.findIndex(a => a.id === newAttendee.id);
          if (existingIndex === -1) {
            // Si no existe, añadir al inicio del array
            attendees.value.unshift(newAttendee);
            
            // Emitir el evento solo si los datos iniciales ya se cargaron y es un nuevo registro
            if (initialDataLoaded) {
              window.dispatchEvent(new CustomEvent('newRegistration', { detail: newAttendee }));
            }
          } else {
            // Si ya existe, actualizar el asistente en su posición actual
            attendees.value[existingIndex] = newAttendee;
          }
        } else if (change.type === 'modified') {
          // Actualizar el asistente existente
          const index = attendees.value.findIndex(a => a.id === change.doc.id);
          if (index !== -1) {
            attendees.value[index] = {
              id: change.doc.id,
              ...change.doc.data(),
              createdAt: formatDateTime(change.doc.data().createdAt),
              updatedAt: formatDateTime(change.doc.data().updatedAt)
            };
          }
        } else if (change.type === 'removed') {
          // Eliminar el asistente
          const index = attendees.value.findIndex(a => a.id === change.doc.id);
          if (index !== -1) {
            attendees.value.splice(index, 1);
          }
        }
      });
      
      // Marcar que los datos iniciales se han cargado después de la primera ejecución
      if (!initialDataLoaded) {
        initialDataLoaded = true;
      }
    });
  };

  const addAttendee = (attendeeData) => {
   // console.log('Nuevo asistente añadido:', attendeeData);
    // Nota: Esta función ahora solo se usa para logging, ya que la adición real
    // se maneja en el listener de onSnapshot
  };

  return { attendees, fetchAttendees, addAttendee };
});