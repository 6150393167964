<template>
    <div class="attendees-view">
      <!-- <h1>Lista de Asistentes</h1> -->
      <AttendeesTable />
    </div>
  </template>
  
  <script>
  import AttendeesTable from '@/components/AttendeeDataTable.vue'
  
  export default {
    components: {
      AttendeesTable
    }
  }
  </script>