<template>
  <div id="app" v-if="authReady">
        <nav v-if="showNav">
          <router-link to="/" class="nav-link">Formulario</router-link> |
          <template v-if="isAuthenticated">
            <!-- <router-link to="/admin" class="nav-link">Inscritos</router-link> | -->
            <router-link to="/asistentes" class="nav-link">Asistentes</router-link> |
            <router-link to="/eventos" class="nav-link">Eventos</router-link> |
            <router-link to="/aliados" class="nav-link">Aliados</router-link> |
            <span @click="logout" style="cursor: pointer;">Cerrar sesión</span>
          </template>
          <router-link v-else to="/login" class="nav-link">Login</router-link>
        </nav>
      <Toast />
      <router-view></router-view>
      </div>
      <div v-else>
      Cargando...
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from './firebase';
import { useToast } from 'primevue/usetoast';
import { useRouter, useRoute } from 'vue-router';
import Toast from 'primevue/toast';

const isAuthenticated = ref(false);
const authReady = ref(false);
const toast = useToast();
const router = useRouter();
const route = useRoute();

const showNav = computed(() => {
  return isAuthenticated.value || route.path === '/login';
});

onMounted(() => {
  onAuthStateChanged(auth, (user) => {
    isAuthenticated.value = !!user;
    authReady.value = true;
    if (!user && route.meta.requiresAuth) {
      router.push('/login');
    }
  });
});

const logout = () => {
  signOut(auth).then(() => {
    router.push('/login');
    toast.add({ severity: 'success', summary: 'Éxito', detail: 'Sesión cerrada correctamente', life: 3000 });
  }).catch((error) => {
    console.error("Error signing out: ", error);
    toast.add({ severity: 'error', summary: 'Error', detail: 'No se pudo cerrar la sesión', life: 3000 });
  });
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}

nav {
  padding: 5px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  margin: 0 10px;
}

nav a.router-link-active {
  color: #4262b9;
}

nav span {
  font-weight: bold;
  color: #2c3e50;
  margin: 0 10px;
}

nav span:hover {
  color: #4262b9;
}
</style>