// services/emailService.js
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '@/firebase';

export const emailService = {
  async sendConfirmationEmail(email, name, eventName, eventDateTime) {
    try {
      //Confirmar informacion enviada.
      //console.log('Sending confirmation email with:', { email, name, eventName, eventDateTime });
      const response = await fetch('/.netlify/functions/send-email', {
        method: 'POST',
        body: JSON.stringify({ 
          type: 'confirmation',
          email, 
          name, 
          eventName,
          eventDateTime
        })
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to send confirmation email: ${response.status} ${response.statusText}. ${errorText}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Detailed error sending confirmation email:', error);
      throw error;
    }
  },

  async sendReferrerEmail(referrerEmail, referrerName, assistantName, eventName, eventDateTime) {
    try {
      const response = await fetch('/.netlify/functions/send-email', {
        method: 'POST',
        body: JSON.stringify({
          type: 'referrer',
          email: referrerEmail,
          name: referrerName,
          assistantName,
          eventName,
          eventDateTime
        })
      });
      if (!response.ok) throw new Error('Failed to send referrer email');
      return await response.json();
    } catch (error) {
      console.error('Error sending referrer email:', error);
      throw error;
    }
  },

  async sendCompanyEmail(assistantName, eventName, eventDateTime, referrerName = null) {
    try {
      const response = await fetch('/.netlify/functions/send-email', {
        method: 'POST',
        body: JSON.stringify({
          type: 'company',
          email: process.env.COMPANY_EMAIL,
          companyName: process.env.COMPANY_NAME,
          assistantName,
          eventName,
          eventDateTime,
          referrerName
        })
      });
      if (!response.ok) throw new Error('Failed to send company email');
      return await response.json();
    } catch (error) {
      console.error('Error sending company email:', error);
      throw error;
    }
  }
};