<template>
    <div class="container">
    <!-- Confirmación Eliminado  -->
    <ConfirmPopup></ConfirmPopup>
      <h2>Gestión de Referidos</h2>
      <form @submit.prevent="createOrUpdateReferrer" class="referrer-form">
        <div class="form-group">
          <label for="referrerCode" class="form-label">Código de Referido</label>
          <InputText 
            v-model="referrerCode" 
            id="referrerCode" 
            class="form-control" 
            placeholder="Código único" 
            required 
            @blur="() => validateReferrerCode(referrerCode, editingReferrerId)"
          />
        </div>
        <div class="form-group">
          <label for="referrerName" class="form-label">Nombre</label>
          <InputText v-model="referrerName" id="referrerName" class="form-control" placeholder="Nombre del referido" required />
        </div>
        <div class="form-group">
          <label for="referrerEmail" class="form-label">Email</label>
          <InputText v-model="referrerEmail" id="referrerEmail" type="email" class="form-control" placeholder="Email del referido" required />
        </div>
        <div class="form-group switch-group">
          <label for="referrerActive" class="form-label switch-label">Activo: </label>
          <InputSwitch v-model="referrerActive" id="referrerActive" class="switch-input" />
        </div>
        <Button type="submit" :label="editingReferrerId ? 'Actualizar Referido' : 'Crear Referido'" class="btn btn-primary btn-block" />
      </form>
  
      <h3>Referidos Registrados</h3>
      <div class="search-bar">
        <InputText v-model="searchQuery" placeholder="Buscar referidos..." class="form-control" />
      </div>
      <div class="table-wrapper">
      <div class="table-container">
        <DataTable :value="filteredReferrers" 
                   class="referrer-list" 
                   :paginator="true" 
                   :rows="10" 
                   :rowsPerPageOptions="[10, 20, 50, 100]"
                   :totalRecords="filteredReferrers.length"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} referidos"
                   responsiveLayout="scroll"
                   dataKey="id"
                   :expandedRows="expandedRows"
                   @row-expand="onRowExpand"
                   @row-collapse="onRowCollapse">
          <Column :expander="true" style="width: 3em" />
          <Column field="code" header="Código"></Column>
          <Column field="name" header="Nombre"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="active" header="Estado">
            <template #body="slotProps">
              {{ slotProps.data.active ? 'Activo' : 'Inactivo' }}
            </template>
          </Column>
          <Column field="totalReferred" header="Total Referidos"></Column>
          <Column header="Acciones" :style="{ width: '200px' }">
            <template #body="slotProps">
              <Button @click="editReferrer(slotProps.data)" icon="pi pi-pencil" class="btn btn-edit" />
              <Button @click="toggleActive(slotProps.data)" :icon="slotProps.data.active ? 'pi pi-eye-slash' : 'pi pi-eye'" :class="slotProps.data.active ? 'btn btn-hide' : 'btn btn-show'" />
              <Button @click="confirmDelete(slotProps.data.id)" icon="pi pi-trash" class="btn btn-danger" />
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="expanded-row">
              <p><strong>Enlace de referido:</strong></p>
              <div class="link-container">
                <InputText :value="slotProps.data.link" readonly class="referrer-link" />
                <Button @click="copyLink(slotProps.data.link)" icon="pi pi-copy" class="btn btn-copy" />
              </div>
            </div>
          </template>
        </DataTable>
      </div>

      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { useToast } from "primevue/usetoast"
  import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc, serverTimestamp, query, where } from 'firebase/firestore'
  import { db } from '@/firebase'
  import { useConfirm } from "primevue/useconfirm"
  import InputText from 'primevue/inputtext'
  import InputSwitch from 'primevue/inputswitch'
  import Button from 'primevue/button'
  import DataTable from 'primevue/datatable'
  import Column from 'primevue/column'
  import ConfirmPopup from 'primevue/confirmpopup'

  const router = useRouter()
  const referrerCode = ref('')
  const referrerName = ref('')
  const referrerEmail = ref('')
  const referrerActive = ref(true)
  const referrers = ref([])
  const toast = useToast()
  const editingReferrerId = ref(null)
  const searchQuery = ref('')
  const expandedRows = ref({})
  const confirm = useConfirm();
  const codeExists = ref(false);
  
  // Función de normalización
const normalizeReferrerCode = (code) => {
  if (!code) return '';
  code = code.toLowerCase();
  code = code.trim();
  code = code.replace(/[^a-z0-9]/g, '');
  code = code.slice(0, 15);
  return code;
};


// Función para verificar si el código ya existe
const validateReferrerCode = async (code, id = null) => {
  const normalizedCode = normalizeReferrerCode(code);
  const q = query(collection(db, "referrers"), where("code", "==", normalizedCode));
  const querySnapshot = await getDocs(q);
  
  // Si estamos editando, excluimos el documento actual de la verificación
  const exists = querySnapshot.docs.some(doc => doc.id !== id);
  
  if (exists) {
    toast.add({
      severity: 'warn',
      summary: 'Código Existente',
      detail: 'Este código de referido ya existe',
      life: 4000
    });
  }
  
  return exists;
};

watch(referrerCode, (newValue) => {
  referrerCode.value = normalizeReferrerCode(newValue);
});

  const filteredReferrers = computed(() => {
    if (!searchQuery.value) return referrers.value
    return referrers.value.filter(referrer => 
      referrer.code.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      referrer.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      referrer.email.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
  })
  
  const createOrUpdateReferrer = async () => {
  try {
    const normalizedCode = normalizeReferrerCode(referrerCode.value);
    
    const codeExists = await validateReferrerCode(normalizedCode, editingReferrerId.value);
    if (codeExists) {
      return; // El toast ya se mostró en validateReferrerCode
    }

    const baseUrl = window.location.origin;
    const referrerData = {
      code: normalizedCode,
      name: referrerName.value,
      email: referrerEmail.value,
      active: referrerActive.value,
      updatedAt: serverTimestamp(),
      link: new URL(router.resolve({ 
        path: '/', 
        query: { ref: normalizedCode } 
      }).href, baseUrl).toString()
    }
    
    if (editingReferrerId.value) {
      await updateDoc(doc(db, "referrers", editingReferrerId.value), referrerData);
      toast.add({severity:'success', summary: 'Éxito', detail:'Referido actualizado exitosamente', life: 3000});
    } else {
      referrerData.createdAt = serverTimestamp();
      referrerData.totalReferred = 0;
      referrerData.eventsReferred = {};
      await addDoc(collection(db, "referrers"), referrerData);
      toast.add({severity:'success', summary: 'Éxito', detail:'Referido creado exitosamente', life: 3000});
    }
    
    resetForm();
    fetchReferrers();
  } catch (error) {
    console.error("Error adding/updating referrer: ", error);
    toast.add({severity:'error', summary: 'Error', detail:'Error al crear/actualizar el referido', life: 3000});
  }
};
  
  const toggleActive = async (referrer) => {
    try {
      await updateDoc(doc(db, "referrers", referrer.id), {
        active: !referrer.active,
        updatedAt: serverTimestamp()
      })
      toast.add({severity:'success', summary: 'Éxito', detail:`Referido ${referrer.active ? 'desactivado' : 'activado'}`, life: 3000})
      fetchReferrers()
    } catch (error) {
      console.error("Error updating active status: ", error)
      toast.add({severity:'error', summary: 'Error', detail:'Error al cambiar el estado del referido', life: 3000})
    }
  }
  

  const confirmDelete = (id) => {
    confirm.require({
        message: '¿Estás seguro de que quieres eliminar este referido?',
        header: 'Confirmación de eliminación',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
        deleteReferrer(id);
        },
        reject: () => {
        toast.add({ severity: 'info', summary: 'Cancelado', detail: 'Has cancelado la eliminación', life: 3000 });
        }
    });
    }
    const deleteReferrer = async (id) => {
        try {
            await deleteDoc(doc(db, "referrers", id))
            toast.add({severity:'success', summary: 'Éxito', detail:'Referido eliminado', life: 3000})
            fetchReferrers()
        } catch (error) {
            console.error("Error removing referrer: ", error)
            toast.add({severity:'error', summary: 'Error', detail:'Error al eliminar el referido', life: 3000})
        }
    }
  
  const editReferrer = (referrer) => {
    referrerCode.value = referrer.code
    referrerName.value = referrer.name
    referrerEmail.value = referrer.email
    referrerActive.value = referrer.active
    editingReferrerId.value = referrer.id
  }
  
  const fetchReferrers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "referrers"))
      referrers.value = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
    } catch (error) {
      console.error("Error fetching referrers: ", error)
      toast.add({severity:'error', summary: 'Error', detail:'Error al obtener los referidos', life: 3000})
    }
  }
  
  const resetForm = () => {
    referrerCode.value = ''
    referrerName.value = ''
    referrerEmail.value = ''
    referrerActive.value = true
    editingReferrerId.value = null
  }
  
  const copyLink = (link) => {
    navigator.clipboard.writeText(link)
      .then(() => {
        toast.add({severity:'success', summary: 'Éxito', detail:'Enlace copiado al portapapeles', life: 3000})
      })
      .catch((error) => {
        console.error('Error al copiar el enlace: ', error)
        toast.add({severity:'error', summary: 'Error', detail:'No se pudo copiar el enlace', life: 3000})
      })
  }
  
  const onRowExpand = (event) => {
   // toast.add({severity:'info', summary: 'Fila Expandida', detail:`${event.data.name}`, life: 3000})
  }
  
  const onRowCollapse = (event) => {
    //toast.add({severity:'success', summary: 'Fila Colapsada', detail:`${event.data.name}`, life: 3000})
  }
  
  onMounted(() => {
    fetchReferrers()
  })
  </script>
  
  <style scoped>
.container {
  max-width: 1200px; /* o el ancho máximo que prefieras */
  width: 90%; /* esto dará un margen del 5% en cada lado */
  padding: 0 15px;
  margin: 0 auto;
}
.event-form {
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.date-time-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.date-time-input {
  width: 100%;
  max-width: 250px; /* Calendar */
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

.form-control {
  width: 100%;
  padding: 6px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
}

.btn-primary {
  background-color: #345cb3;
  border: none;
  color: white;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #4089b3;
}

.event-list {
  width: 100%;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}

.btn-danger:hover {
  background-color: #c82333;
}

.btn-edit {
  background-color: #ffc107;
  color: black;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-edit:hover {
  background-color: #e0a800;
}

.search-bar {
  margin-bottom: 20px;
}

.p-paginator {
  justify-content: flex-end;
  padding: 1rem 0;
}

.p-paginator .p-paginator-current {
  margin-left: auto;
}

.p-progressbar {
  height: .5rem;
  background-color: #D8DADC;
}

.p-progressbar .p-progressbar-value {
  background-color: #607D8B;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .container {
    width: 95%; /* menos margen en pantallas pequeñas */
  }
    .date-time-input {
    max-width: 100%; /* ancho completo en pantallas pequeñas */
  }
}
.btn-hide {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}

.btn-hide:hover {
  background-color: #5a6268;
}

.btn-show {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}

.btn-show:hover {
  background-color: #218838;
}
.p-datatable .p-datatable-thead > tr > th:first-child,
.p-datatable .p-datatable-tbody > tr > td:first-child {
  width: 3em;
  text-align: center;
}

.p-datatable .p-datatable-tbody > tr > td:first-child .p-column-title {
  display: none;
}
.p-editor-container {
  margin-bottom: 1rem;
}

.p-calendar {
  width: 100%;
}

.p-calendar .p-button-icon-only {
  width: 3rem;
}

.p-d-flex {
  display: flex;
}

.p-jc-between {
  justify-content: space-between;
}


/* Estilos del switch */

.switch-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px; /* Espacio entre el label y el switch */
}

.switch-label {
  margin-bottom: 0;
  margin-right: 10px; /* Espacio adicional a la derecha del label */
  flex: 0 0 auto; /* Evita que el label crezca */
}

.switch-input {
  flex-shrink: 0; /* Evita que el switch se encoja */
}
::v-deep .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #345cb3 /* Cambia esto al color que prefieras */
}

/* Ajuste opcional para mejorar la apariencia en móviles */
@media (max-width: 768px) {
  .switch-group {
    justify-content: space-between;
  }
}

/* Expandir iten en la tabla */
.expanded-row {
  padding: 0.5rem;
  background-color: #bec8d3;
}

.link-container {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.referrer-link {
  flex-grow: 1;
  margin-right: 0.5rem;
}

.btn-copy {
  flex-shrink: 0;
}
/* table wrapper */

.table-wrapper {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.table-container {
  width: 100%;
  overflow-x: auto;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
}

.referrer-list {
  min-width: 750px; /* Ajusta este valor según sea necesario */
}

/* Estilos adicionales para mejorar la apariencia de la tabla */
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background-color: #f1f3f5;
  color: #495057;
  font-weight: 600;
  text-align: left;
  padding: 1rem;
  border-bottom: 2px solid #e0e0e0;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e0e0e0;
}

::v-deep .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f8f9fa;
}

::v-deep .p-paginator {
  background-color: transparent;
  padding: 1rem 0;
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .table-wrapper {
    padding: 10px;
  }

  .referrer-list {
    min-width: 100%;
  }

  ::v-deep .p-datatable .p-datatable-thead > tr > th,
  ::v-deep .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem;
  }
}

</style>