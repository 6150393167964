<template>
    <div v-if="event" class="event-details-container">
      <h2>{{ event.name }}</h2>
      <p><strong>Modalidad:</strong> {{ event.modality }}</p>
      <p><strong>Fecha y Hora:</strong> {{ formatDateTime(event.dateTime) }}</p>
      <div class="description" v-html="event.description"></div>
      <Button @click="goBack" label="Volver" class="p-button-secondary" />
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { doc, getDoc } from 'firebase/firestore';
  import { db } from '@/firebase';
  import { formatDateTime } from '@/utils/dateUtils';
  import Button from 'primevue/button';
  
  const route = useRoute();
  const router = useRouter();
  const event = ref(null);
  
  const fetchEventDetails = async () => {
    try {
      const eventDoc = await getDoc(doc(db, "events", route.params.id));
      if (eventDoc.exists()) {
        event.value = { id: eventDoc.id, ...eventDoc.data() };
      } else {
        console.log("No such event!");
      }
    } catch (error) {
      console.error("Error fetching event details: ", error);
    }
  };
  
  const goBack = () => {
    router.go(-1);
  };
  
  onMounted(() => {
    fetchEventDetails();
  });
  </script>
  
  <style scoped>
  .event-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .description {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  </style>