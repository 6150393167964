<template>
  <div class="container">
    <h2>Gestión de Eventos</h2>
    <form @submit.prevent="createOrUpdateEvent" class="event-form">
      <div class="form-group">
        <label for="eventFormId" class="form-label">Formulario de Inscripción</label>
        <Dropdown v-model="eventFormId" :options="formOptions" optionLabel="name" optionValue="id" placeholder="Seleccione un formulario" class="form-control">
          <template #option="slotProps">
            <div class="form-option">
              <div>{{slotProps.option.name}}</div>
              <small>Type: {{slotProps.option.type}}</small>
            </div>
          </template>
        </Dropdown>
      </div>
      
      <div class="form-group">
        <label for="eventCode" class="form-label">Centro de costos</label>
        <InputText 
            v-model="eventCode" 
            id="eventCode" 
            
            class="form-control" 
            placeholder="Centro de costos" 
            required 
            @blur="() => validateEventCode(eventCode, editingEventId)"
            @input="onInput"
          />
      </div>
      <div class="form-group">
        <label for="eventName" class="form-label">Nombre </label>
        <InputText v-model="eventName" id="eventName" class="form-control" placeholder="Nombre del evento" required />
      </div>
      <!-- Dropdown para la modalidad -->
      <div class="form-group">
        <label for="eventModality" class="form-label">Modalidad</label>
        <Dropdown
          v-model="eventModality"
          :options="modalityOptions"
          optionLabel="name"
          optionValue="value"
          placeholder="Seleccione la modalidad"
          class="form-control"
        />
      </div>
      <div class="form-group date-time-group">
      <label for="eventDateTime" class="form-label">Fecha y Hora </label>
      <Calendar v-model="eventDateTime" id="eventDateTime" 
                  :showTime="true" 
                  hourFormat="12" 
                  dateFormat="dd/mm/yy"
                  :showIcon="true"
                  class="form-control date-time-input" 
                  required
                  :manualInput="false"
                  v-model:visible="calendarVisible">
          <template #footer>
            <div class="p-d-flex p-jc-between">
              <Button label="Hoy" icon="pi pi-calendar" @click="setToday" class="p-button-text" />
              <Button label="Limpiar" icon="pi pi-times" @click="clearDate" class="p-button-text" />
              <Button label="OK" icon="pi pi-check" @click="closeCalendar" class="p-button-text" />
            </div>
          </template>
        </Calendar>
      </div>
      <div class="form-group">
        <label for="eventDescription" class="form-label">Descripción </label>
        <Editor ref="editorRef" v-model="eventDescription" editorStyle="height: 250px" />
      </div>
      
      <div class="form-group switch-group">
        <label for="eventVisible" class="form-label switch-label">Visible: </label>
        <InputSwitch v-model="eventVisible" id="eventVisible" class="switch-input" />
      </div>
      <Button type="submit" :label="editingEventId ? 'Actualizar Evento' : 'Crear Evento'" class="btn btn-primary btn-block" />
    </form>

    
    <h3>Eventos Registrados</h3>
    <div class="search-bar">
      <InputText v-model="searchQuery" placeholder="Buscar eventos..." class="form-control" />
    </div>
    <div class="table-container">
      <DataTable :value="filteredEvents" 
                 class="event-list" 
                 :paginator="true" 
                 :rows="10" 
                 :rowsPerPageOptions="[10, 20, 50, 100]"
                 :totalRecords="filteredEvents.length"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} eventos"
                 responsiveLayout="scroll"
                 @rowReorder="onRowReorder"
                 dataKey="id">
        <Column :rowReorder="true" style="width: 3em" />
        <Column field="code" header="C. Costos"></Column>
        <Column field="name" header="Nombre"></Column>
        <Column field="dateTime" header="Fecha y Hora">
          <template #body="slotProps">
            {{ formatDateTime(slotProps.data.dateTime) }}
          </template>
        </Column>
        <Column field="modality" header="Modalidad">
          <template #body="slotProps">
            {{ slotProps.data.modality }}
          </template>
        </Column>
        <Column field="visible" header="Estado">
          <template #body="slotProps">
            {{ slotProps.data.visible ? 'Visible' : 'Oculto' }}
          </template>
        </Column>
        <Column header="Acciones" :style="{ width: '200px' }">
          <template #body="slotProps">
            <Button @click="editEvent(slotProps.data)" icon="pi pi-pencil" class="btn btn-edit" />
            <Button @click="toggleVisibility(slotProps.data)" :icon="slotProps.data.visible ? 'pi pi-eye-slash' : 'pi pi-eye'" :class="slotProps.data.visible ? 'btn btn-hide' : 'btn btn-show'" />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup>

//Inicia  08-06-24 cambio de estructura, para la creacion de eventos y correcto filtrado. Voy en ordenar eventos.
import { ref, onMounted, computed, watch, nextTick} from 'vue'
import { useToast } from "primevue/usetoast"
import { collection, addDoc, getDocs, doc, updateDoc, writeBatch, serverTimestamp, query, where } from 'firebase/firestore'
import { db } from '@/firebase'
import { formatDateTime } from '@/utils/dateUtils'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dropdown from 'primevue/dropdown'
import Editor from 'primevue/editor'

const eventCode = ref('')
const eventName = ref('')
const eventDescription = ref('')
const eventDateTime = ref(null)
const eventFormId = ref('')
const eventVisible = ref(true)
const events = ref([])
const forms = ref([])
const toast = useToast()
const editingEventId = ref(null)
const searchQuery = ref('')
const editorRef = ref(null)


const formOptions = computed(() => {
  return forms.value.map(form => ({
    id: form.id,
    name: form.name,
    type: form.type
  }))
})


//Validar eventCode
const normalizeEventCode = (code) => {
  if (!code) return '';
  // Elimina todos los caracteres no numéricos
  return code.replace(/[^0-9]/g, '');
};

//Para impedir cualquier letra en el Input
const onInput = (event) => {
      event.target.value = normalizeEventCode(event.target.value);
      eventCode.value = event.target.value;
    };

const validateEventCode = async (code, id = null) => {
  const normalizedCode = normalizeEventCode(code);
  if (!normalizedCode) {
    toast.add({
      severity: 'warn',
      summary: 'Código Inválido',
      detail: 'El centro de costos debe contener al menos un número',
      life: 4000
    });
    return true; // Consideramos que existe para evitar continuar con un código vacío
  }
  const q = query(collection(db, "events"), where("code", "==", normalizedCode));
  const querySnapshot = await getDocs(q);
  
  // Si estamos editando, excluimos el documento actual de la verificación
  const exists = querySnapshot.docs.some(doc => doc.id !== id);
  
  if (exists) {
    toast.add({
      severity: 'warn',
      summary: 'Código Existente',
      detail: 'Este centro de costos ya existe',
      life: 4000
    });
  }
  
  return exists;
};


watch(eventCode, (newValue) => {
  eventCode.value = normalizeEventCode(newValue);
});

const eventModality = ref('')
const modalityOptions = [
  { name: 'Virtual', value: 'Virtual' },
  { name: 'Presencial', value: 'Presencial' }
]

//Funciones calendario
const setToday = () => {
  eventDateTime.value = new Date()
}
const clearDate = () => {
  eventDateTime.value = null
}

const calendarVisible = ref(false)
const closeCalendar = () => {
  const calendarOverlay = document.querySelector('.p-datepicker')
  if (calendarOverlay) {
    calendarOverlay.style.display = 'none'
  }
}

const filteredEvents = computed(() => {
  if (!searchQuery.value) return events.value
  return events.value.filter(event => 
    event.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    formatDateTime(event.dateTime).toLowerCase().includes(searchQuery.value.toLowerCase())
  )
})

const onRowReorder = async (event) => {
  const { dragIndex, dropIndex } = event
  const movedItem = events.value.splice(dragIndex, 1)[0]
  events.value.splice(dropIndex, 0, movedItem)

  // Actualizar el orden en Firestore
  try {
    const batch = writeBatch(db)
    events.value.forEach((event, index) => {
      const eventRef = doc(db, "events", event.id)
      batch.update(eventRef, { order: index })
    })
    await batch.commit()
    toast.add({severity:'success', summary: 'Éxito', detail:'Orden de eventos actualizado', life: 3000})
  } catch (error) {
    console.error("Error updating event order: ", error)
    toast.add({severity:'error', summary: 'Error', detail:'Error al actualizar el orden de los eventos', life: 3000})
  }
}

const createOrUpdateEvent = async () => {
  try {
    const normalizedCode = normalizeEventCode(eventCode.value);
    const codeExists = await validateEventCode(normalizedCode, editingEventId.value);
    if (codeExists) {
      return; // El toast ya se mostró en validateEventCode
    }

    const eventData = {
      code: normalizedCode,
      name: eventName.value,
      modality: eventModality.value,
      description: eventDescription.value,
      dateTime: eventDateTime.value,
      formId: eventFormId.value,
      visible: eventVisible.value,
      updatedAt: serverTimestamp()
    }
    
    if (editingEventId.value) {
      await updateDoc(doc(db, "events", editingEventId.value), eventData)
      toast.add({severity:'success', summary: 'Éxito', detail:'Evento actualizado exitosamente', life: 3000})
    } else {
      const maxOrder = Math.max(...events.value.map(e => e.order), -1)
      const newEventData = {
        ...eventData,
        order: maxOrder + 1,
        createdAt: serverTimestamp()
      }
      await addDoc(collection(db, "events"), newEventData)
      toast.add({severity:'success', summary: 'Éxito', detail:'Evento creado exitosamente', life: 3000})
    }
    resetForm()
    fetchEvents()
  } catch (error) {
    console.error("Error adding/updating document: ", error)
    toast.add({severity:'error', summary: 'Error', detail:'Error al crear/actualizar el evento', life: 3000})
  }
}

const toggleVisibility = async (event) => {
  try {
    await updateDoc(doc(db, "events", event.id), {
      visible: !event.visible
    })
    toast.add({severity:'success', summary: 'Éxito', detail:`Evento ${event.visible ? 'ocultado' : 'mostrado'}`, life: 3000})
    fetchEvents()
  } catch (error) {
    console.error("Error updating visibility: ", error)
    toast.add({severity:'error', summary: 'Error', detail:'Error al cambiar la visibilidad del evento', life: 3000})
  }
}

//Sin uso por ahora
const deleteEvent = async (id) => {
  try {
    await deleteDoc(doc(db, "events", id))
    toast.add({severity:'success', summary: 'Éxito', detail:'Evento eliminado', life: 3000})
    fetchEvents()
  } catch (error) {
    console.error("Error removing document: ", error)
    toast.add({severity:'error', summary: 'Error', detail:'Error al eliminar el evento', life: 3000})
  }
}


const editEvent = (event) => {
  eventCode.value = normalizeEventCode(event.code);
  eventName.value = event.name
  eventModality.value = event.modality || ''
  eventDescription.value = event.description || ''
  eventDateTime.value = event.dateTime.toDate()
  eventFormId.value = event.formId
  eventVisible.value = event.visible
  editingEventId.value = event.id

  //Para que el editor traiga los datos corretamente.
  nextTick(() => {
    if (editorRef.value && editorRef.value.quill) {
      editorRef.value.quill.root.innerHTML = eventDescription.value
    }
  })
}

const fetchEvents = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "events"))
    events.value = querySnapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data(),
        modality: doc.data().modality || 'No especificada',
        description: doc.data().description || '', 
        order: doc.data().order || 0
      }))
      .sort((a, b) => a.order - b.order)
  } catch (error) {
    console.error("Error fetching events: ", error)
    toast.add({severity:'error', summary: 'Error', detail:'Error al obtener los eventos', life: 3000})
  }
}

const fetchForms = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "forms"))
    forms.value = querySnapshot.docs
      .map(doc => ({
        id: doc.id,
        name: doc.data().name,
        type: doc.data().type
      }))
     // .filter(form => form.type === "registration") // Solo obtener formularios de tipo registration
    
    // Si no hay formulario seleccionado, selecciona el "web-standard" por defecto
    if (!eventFormId.value && forms.value.length > 0) {
      const defaultForm = forms.value.find(form => form.name === "web-standard")
      if (defaultForm) {
        eventFormId.value = defaultForm.id
      } else {
        // Si no se encuentra "web-standard", selecciona el primer formulario disponible
        eventFormId.value = forms.value[0].id
      }
    }
  } catch (error) {
    console.error("Error fetching forms: ", error)
    toast.add({severity:'error', summary: 'Error', detail:'Error al obtener los formularios', life: 3000})
  }
}

const resetForm = () => {
  eventCode.value = ''
  eventName.value = ''
  eventModality.value = '' 
  eventDescription.value = ''
  eventDateTime.value = null
  eventFormId.value = forms.value.find(form => form.name === "web-standard")?.id || forms.value[0]?.id || ''
  eventVisible.value = true
  editingEventId.value = null

}

onMounted(() => {
  fetchEvents()
  fetchForms()
})
</script>

<style scoped>
.container {
  max-width: 1200px; /* o el ancho máximo que prefieras */
  width: 90%; /* esto dará un margen del 5% en cada lado */
  padding: 0 15px;
  margin: 0 auto;
}
.event-form {
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.date-time-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.date-time-input {
  width: 100%;
  max-width: 250px; /* Calendar */
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

.form-control {
  width: 100%;
  padding: 6px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
}

.btn-primary {
  background-color: #345cb3;
  border: none;
  color: white;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #4089b3;
}

.event-list {
  width: 100%;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}

.btn-danger:hover {
  background-color: #c82333;
}

.btn-edit {
  background-color: #ffc107;
  color: black;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-edit:hover {
  background-color: #e0a800;
}

.search-bar {
  margin-bottom: 20px;
}

.p-paginator {
  justify-content: flex-end;
  padding: 1rem 0;
}

.p-paginator .p-paginator-current {
  margin-left: auto;
}

.p-progressbar {
  height: .5rem;
  background-color: #D8DADC;
}

.p-progressbar .p-progressbar-value {
  background-color: #607D8B;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .container {
    width: 95%; /* menos margen en pantallas pequeñas */
  }
    .date-time-input {
    max-width: 100%; /* ancho completo en pantallas pequeñas */
  }
}
.btn-hide {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}

.btn-hide:hover {
  background-color: #5a6268;
}

.btn-show {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}

.btn-show:hover {
  background-color: #218838;
}
.p-datatable .p-datatable-thead > tr > th:first-child,
.p-datatable .p-datatable-tbody > tr > td:first-child {
  width: 3em;
  text-align: center;
}

.p-datatable .p-datatable-tbody > tr > td:first-child .p-column-title {
  display: none;
}
.p-editor-container {
  margin-bottom: 1rem;
}

.p-calendar {
  width: 100%;
}

.p-calendar .p-button-icon-only {
  width: 3rem;
}

.p-d-flex {
  display: flex;
}

.p-jc-between {
  justify-content: space-between;
}


/* Estilos del switch */

.switch-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px; /* Espacio entre el label y el switch */
}

.switch-label {
  margin-bottom: 0;
  margin-right: 10px; /* Espacio adicional a la derecha del label */
  flex: 0 0 auto; /* Evita que el label crezca */
}

.switch-input {
  flex-shrink: 0; /* Evita que el switch se encoja */
}
::v-deep .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #345cb3 /* Cambia esto al color que prefieras */
}

/* Ajuste opcional para mejorar la apariencia en móviles */
@media (max-width: 768px) {
  .switch-group {
    justify-content: space-between;
  }
}

</style>