<template>
  <div class="container mt-4">
    <!-- Contenedor para el referido -->
    <div class="d-flex justify-content-end mb-2">
      <div v-if="referrerCode" class="referido-label">
        Ref: {{ referrerCode }}
      </div>
    </div>

    <!-- Selector de eventos -->
    <div class="form-group">
      <label for="eventCode" class="form-label">*</label>
      <select v-model="selectedEventCode" id="eventCode" class="form-control" required>
        <option value="">Seleccione un evento</option>
        <option v-for="event in events" :key="event.code" :value="event.code">
          {{ event.name }}
        </option>
      </select>
    </div>

    <!-- Formulario de asistentes -->
    <div v-for="(assistant, index) in assistantData" :key="index">
      <div class="card mb-3">
        <div class="card-body">
          <h4 class="card-title d-flex justify-content-between align-items-center">
            Asistente {{ index + 1 }}
            <div>
              <button v-if="index > 0" @click="cancelAssistant(index)" class="btn btn-danger btn-sm">Cancelar asistente {{ index + 1 }}</button>
            </div>
          </h4>
          <form @submit.prevent class="needs-validation">
            <div class="form-group">
              <label :for="'docId_' + index" class="form-label">Documento*</label>
              <input v-model="assistant.docId" :id="'docId_' + index" class="form-control" placeholder="Documento" required>
            </div>
            <div class="form-group">
              <label :for="'name_' + index" class="form-label">Nombre Completo*</label>
              <input v-model="assistant.name" :id="'name_' + index" class="form-control" placeholder="Nombre" required>
            </div>
            <div class="form-group">
              <label :for="'email_' + index" class="form-label">Correo*</label>
              <input v-model="assistant.email" :id="'email_' + index" type="email" class="form-control" placeholder="Correo" required>
            </div>
            <div class="form-group">
              <label :for="'city_' + index" class="form-label">Ciudad*</label>
              <input v-model="assistant.city" :id="'city_' + index" class="form-control" placeholder="Ciudad" required>
            </div>
            <div class="form-group">
              <label :for="'cellPhone_' + index" class="form-label">Celular*</label>
              <input v-model="assistant.cellPhone" :id="'cellPhone_' + index" type="tel" class="form-control" placeholder="Celular" required pattern="[0-9]*">
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Botones para copiar datos a facturación -->
    <div class="d-flex flex-wrap justify-content-start mb-3">
      <button 
        v-for="(assistant, index) in assistantData" 
        :key="'copy-' + index"
        @click="copyToInvoice(index)" 
        class="btn btn-info btn-sm mr-2 mb-2"
      >
        Copiar a facturación asistente {{ index + 1 }}
      </button>
    </div>
      
    <hr>

    <!-- Botón para añadir nuevo asistente -->
    <div class="d-flex justify-content-between mb-3">
      <button @click="addAssistantForm" class="btn btn-secondary">Añadir otro asistente</button>
    </div>
      <!-- Línea separadora -->
      <!-- Datos de facturación -->
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Datos de facturación</h4>
          <form @submit.prevent class="needs-validation">
            <div class="form-group">
              <label for="billingName" class="form-label">Factura a nombre de*</label>
              <input v-model="billingData.billingName" id="billingName" class="form-control" placeholder="Factura a nombre de" required>
            </div>
            <div class="form-group">
              <label for="billingDocType" class="form-label">Tipo de Documento*</label>
              <select v-model="billingData.billingDocType" id="billingDocType" class="form-control" required>
                <option value="CC">CC</option>
                <option value="Nit">Nit</option>
              </select>
            </div>
            <div class="form-group">
              <label for="billingDoc" class="form-label">Nro Documento*</label>
              <input v-model="billingData.billingDoc" id="billingDoc" class="form-control" placeholder="Nro Documento" required>
            </div>
            <div class="form-group">
              <label for="billingAddress" class="form-label">Dirección*</label>
              <input v-model="billingData.billingAddress" id="billingAddress" class="form-control" placeholder="Dirección" required>
            </div>
            <div class="form-group">
              <label for="billingEmail" class="form-label">Email*</label>
              <input v-model="billingData.billingEmail" id="billingEmail" type="email" class="form-control" placeholder="Email" required>
            </div>
            <div class="form-group">
              <label for="billingCity" class="form-label">Ciudad</label>
              <input v-model="billingData.billingCity" id="billingCity" class="form-control" placeholder="Ciudad">
            </div>
            <div class="form-group">
              <label for="billingPhone" class="form-label">Teléfono</label>
              <input v-model="billingData.billingPhone" id="billingPhone" class="form-control" placeholder="Teléfono">
            </div>
            <div class="form-group">
              <label for="billingContact" class="form-label">Contacto</label>
              <input v-model="billingData.billingContact" id="billingContact" class="form-control" placeholder="Contacto">
            </div>
            <div class="form-group">
              <label for="billingCellPhone" class="form-label">Celular</label>
              <input v-model="billingData.billingCellPhone" id="billingCellPhone" class="form-control" placeholder="Celular">
            </div>
            <div class="form-group">
              <label class="form-label d-flex justify-content-between align-items-center">
                Autoretenedor de IVA
                <div>
                  <input type="radio" v-model="billingData.selfRetainerIVA" id="selfRetainerIVA_si" value="Sí">
                  <label for="selfRetainerIVA_si" class="form-check-label mr-2">Sí</label>
                  <input type="radio" v-model="billingData.selfRetainerIVA" id="selfRetainerIVA_no" value="No">
                  <label for="selfRetainerIVA_no" class="form-check-label">No</label>
                </div>
              </label>
            </div>
            <div class="form-group">
              <label class="form-label d-flex justify-content-between align-items-center">
                Autoretenedor de Renta
                <div>
                  <input type="radio" v-model="billingData.selfRetainerREN" id="selfRetainerREN_si" value="Sí">
                  <label for="selfRetainerREN_si" class="form-check-label mr-2">Sí</label>
                  <input type="radio" v-model="billingData.selfRetainerREN" id="selfRetainerREN_no" value="No">
                  <label for="selfRetainerREN_no" class="form-check-label">No</label>
                </div>
              </label>
            </div>
            <div class="form-group">
              <label class="form-label d-flex justify-content-between align-items-center">
                Autoretenedor de ICA
                <div>
                  <input type="radio" v-model="billingData.selfRetainerICA" id="selfRetainerICA_si" value="Sí">
                  <label for="selfRetainerICA_si" class="form-check-label mr-2">Sí</label>
                  <input type="radio" v-model="billingData.selfRetainerICA" id="selfRetainerICA_no" value="No">
                  <label for="selfRetainerICA_no" class="form-check-label">No</label>
                </div>
              </label>
            </div>
            <div class="form-group">
              <label class="form-label d-flex justify-content-between align-items-center">
                Gran contribuyente
                <div>
                  <input type="radio" v-model="billingData.bigContributor" id="bigContributor_si" value="Sí">
                  <label for="bigContributor_si" class="form-check-label mr-2">Sí</label>
                  <input type="radio" v-model="billingData.bigContributor" id="bigContributor_no" value="No">
                  <label for="bigContributor_no" class="form-check-label">No</label>
                </div>
              </label>
            </div>
            <div class="form-group">
              <label for="observations" class="form-label">Observaciones</label>
              <textarea v-model="billingData.observations" id="observations" class="form-control" placeholder="Observaciones"></textarea>
            </div>
          </form>
        </div>
      </div>
      <hr>
      <!-- Checkbox de aceptar políticas -->
      <div class="form-check mt-3">
        <input type="checkbox" class="form-check-input" id="acceptPolicies" v-model="acceptPolicies" required>
        <label class="form-check-label" for="acceptPolicies">Acepto las políticas de datos*</label>
      </div>
  
      <!-- Botón de inscribirse -->
      <button @click="submitAllForms" class="btn btn-register btn-primary btn-block mt-3" :disabled="isSubmitting">
        <span v-if="!isSubmitting">Inscribirse</span>
        <span v-else>
          <i class="pi pi-spinner pi-spin"></i> Enviando...
        </span>
      </button>
  
      <!-- Diálogo de inscripción exitosa -->
      <Dialog v-model:visible="showSuccessDialog" modal :header="'Éxito'" :style="{ width: '50vw' }">
        <p>Inscripción exitosa.</p>
        <template #footer>
          <Button label="OK" icon="pi pi-check" @click="closeSuccessDialog" autofocus />
        </template>
      </Dialog>
    </div>
</template>
  
<script>
import { useAttendeeStore } from '@/stores/attendeeStore';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { db } from '@/firebase';
import { collection, getDocs, query, where, orderBy, addDoc, serverTimestamp, doc, updateDoc, increment } from 'firebase/firestore';
import { useToast } from 'primevue/usetoast';
import { emailService } from '@/services/emailService';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { formatDateTime } from '@/utils/dateUtils';


export default {
  components: {
    Dialog,
    Button
  },
  setup() {
    const attendeeStore = useAttendeeStore();
    const route = useRoute();
    const referrerCode = ref('');
    const selectedEventCode = ref('');





//Obtener informacion de referido si existe
const getReferrerInfo = async (referrerCode) => {
  if (!referrerCode) return null;

  const referrerQuery = query(collection(db, 'referrers'), where('code', '==', referrerCode));
  const referrerSnapshot = await getDocs(referrerQuery);
  
  if (referrerSnapshot.empty) {
    console.log('No referrer found with this code');
    return null;
  }

  return referrerSnapshot.docs[0].data();
};

// Función para normalizar el código de referido
const normalizeReferrerCode = (code) => {
  if (!code) return 'icef';
  
  // Convertir a minúsculas
  code = code.toLowerCase();
  // Convertir a mayúsculas
  //code = code.toUpperCase();

  // Eliminar espacios en blanco al inicio y al final
  code = code.trim();
  
  // Eliminar caracteres especiales y espacios en medio
  code = code.replace(/[^a-z0-9]/g, '');
  
  // Limitar a una longitud máxima (por ejemplo, 10 caracteres)
  //code = code.slice(0, 10);
  
  return code || 'icef';
};
   
const assistantData = ref([
  {
    docId: '',
    name: '',
    email: '',
    city: '',
    cellPhone: '',
    referrerCode: 'icef'
  }
]);
  
const billingData = ref({
  billingName: '',
  billingDocType: 'CC',
  billingDoc: '',
  billingAddress: '',
  billingEmail: '',
  billingCity: '',
  billingPhone: '',
  billingContact: '',
  billingCellPhone: '',
  selfRetainerIVA: '',
  selfRetainerREN: '',
  selfRetainerICA: '',
  bigContributor: '',
  observations: ''
});
  
const events = ref([]);
const acceptPolicies = ref(false);
const toast = useToast();
  
const fetchEvents = async () => {
  try {
    const eventsQuery = query(
      collection(db, 'events'),
      where('visible', '==', true),
      orderBy('order', 'asc')
    );
    const querySnapshot = await getDocs(eventsQuery);
    events.value = querySnapshot.docs
      .map(doc => {
        const eventData = doc.data();
        const date = new Date(eventData.dateTime.seconds * 1000 + eventData.dateTime.nanoseconds / 1000000);
        const formattedDateTime = formatDateTime(date);
        
        return {
          code: eventData.code,
          name: eventData.name,
          order: eventData.order,
          visible: eventData.visible,
          dateTime: formattedDateTime,
          description: eventData.description,
          formId: eventData.formId,
          totalAttendees: eventData.totalAttendees
        };
      })
      .filter(event => event.visible)
      .sort((a, b) => a.order - b.order);
  } catch (error) {
    console.error('Error al cargar los eventos:', error);
    toast.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar los eventos', life: 3000 });
  }
};

    onMounted(() => {
      fetchEvents();
      precachePSEPage();
      referrerCode.value = normalizeReferrerCode(route.query.ref || 'icef');
      if (referrerCode.value) {
        assistantData.value[0].referrerCode = referrerCode.value;
      }
    });
      
    const validateForm = () => {
      if (!selectedEventCode.value) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Por favor, seleccione un evento', life: 3000 });
        return false;
      }

      for (const assistant of assistantData.value) {
        if (!assistant.docId || !assistant.name || !assistant.email || !assistant.city || !assistant.cellPhone) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'Por favor, complete todos los campos obligatorios de los asistentes', life: 3000 });
          return false;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(assistant.email)) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'Por favor, ingrese un correo electrónico válido para los asistentes', life: 3000 });
          return false;
        }
        if (!/^[0-9]*$/.test(assistant.cellPhone)) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'Por favor, ingrese un número de celular válido para los asistentes', life: 3000 });
          return false;
        }
      }
    
      const { billingName, billingDocType, billingDoc, billingAddress, billingEmail } = billingData.value;
      if (!billingName || !billingDocType || !billingDoc || !billingAddress || !billingEmail) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Por favor, complete todos los campos obligatorios de facturación', life: 3000 });
        return false;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(billingEmail)) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Por favor, ingrese un correo electrónico válido para la facturación', life: 3000 });
        return false;
      }
    
      if (!acceptPolicies.value) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Debe aceptar las políticas de datos', life: 3000 });
        return false;
      }
    
      return true;
    };
  
    const submitForm = async (formData) => {
      try {
        const selectedEventData = events.value.find(event => event.code === selectedEventCode.value);
        const attendeeData = {
          ...formData,
          eventCode: selectedEventCode.value,
          eventName: selectedEventData ? selectedEventData.name : '',
          referrerCode: normalizeReferrerCode(formData.referrerCode || referrerCode.value || 'icef'),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };
        const docRef = await addDoc(collection(db, 'attendees'), attendeeData);
         // Sin embargo, podemos usar esta función para propósitos de logging si es necesario
        attendeeStore.addAttendee({ id: docRef.id, ...attendeeData });

        // Actualizar el contador de asistentes en el evento
        const eventQuery = query(collection(db, 'events'), where('code', '==', selectedEventCode.value));
        const eventSnapshot = await getDocs(eventQuery);
        
        if (!eventSnapshot.empty) {
          const eventDoc = eventSnapshot.docs[0];
          const updateData = {
            totalAttendees: increment(1),
            updatedAt: serverTimestamp()
          };

          // Si hay un código de referido, actualiza el contador de manera segura
          if (attendeeData.referrerCode) {
            updateData[`referrerAttendees.${attendeeData.referrerCode}`] = increment(1);
          }

          await updateDoc(eventDoc.ref, updateData);
        }

        // Actualizar el contador de referidos si hay un referido
        if (attendeeData.referrerCode) {
          const referrerQuery = query(collection(db, 'referrers'), where('code', '==', attendeeData.referrerCode));
          const referrerSnapshot = await getDocs(referrerQuery);

          if (!referrerSnapshot.empty) {
            const referrerDoc = referrerSnapshot.docs[0];
            await updateDoc(referrerDoc.ref, {
              totalReferred: increment(1),
              [`eventsReferred.${selectedEventCode.value}`]: increment(1),
              updatedAt: serverTimestamp()
            });
          } else {
            // Si el referrer no existe, lo creamos
            await addDoc(collection(db, 'referrers'), {
              code: attendeeData.referrerCode,
              name: 'Sin Asignar', // Puedes ajustar esto según tus necesidades
              email: '',
              active: true,
              link: '',
              totalReferred: 1,
              eventsReferred: {
                [selectedEventCode.value]: 1
              },
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp()
            });
          }
        }
        console.log('Éxito: Inscripción exitosa');
        //toast.add({ severity: 'success', summary: 'Éxito', detail: 'Inscripción exitosa', life: 3000 });
        // Emitir un evento para notificar sobre el nuevo registro
        emitNewRegistration(attendeeData);
        
        return true;
      } catch (error) {
        console.error('Error al inscribir:', error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Error al inscribir: ' + error.message, life: 3000 });
        return false;
      }
    };
    // Función para emitir el evento de nuevo registro
    const emitNewRegistration = (attendeeData) => {
      const event = new CustomEvent('newRegistration', { detail: attendeeData });
      window.dispatchEvent(event);
    };

    const addAssistantForm = () => {
      assistantData.value.push({
        docId: '',
        name: '',
        email: '',
        city: '',
        cellPhone: '',
        referrerCode: normalizeReferrerCode(referrerCode.value || 'icef')
      });
      toast.add({ severity: 'info', summary: 'Info', detail: 'Nuevo asistente añadido', life: 3000 });
    };
    
    const copyToInvoice = (index) => {
      if (index >= 0 && index < assistantData.value.length) {
        const assistant = assistantData.value[index];
        billingData.value.billingName = assistant.name;
        billingData.value.billingDoc = assistant.docId;
        billingData.value.billingEmail = assistant.email;
        billingData.value.billingCity = assistant.city;
        billingData.value.billingCellPhone = assistant.cellPhone;
        toast.add({ severity: 'info', summary: 'Info', detail: `Datos del asistente ${index + 1} copiados a facturación`, life: 3000 });
      } else {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No se pudo copiar los datos del asistente', life: 3000 });
      }
    };
  
    const cancelAssistant = (index) => {
      assistantData.value.splice(index, 1);
      toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Asistente removido', life: 3000 });
    };

    const sendEmails = async (assistant, eventName, eventDateTime, referrerInfo) => {
      try {
        // Enviar correo de confirmación al asistente
        await emailService.sendConfirmationEmail(assistant.email, assistant.name, eventName, eventDateTime);
        
        // Si hay información del referido, enviar correo al referrer
        if (referrerInfo) {
          await emailService.sendReferrerEmail(referrerInfo.email, referrerInfo.name, assistant.name, eventName, eventDateTime);
        }
        
        // Enviar correo a la compañía
        await emailService.sendCompanyEmail(assistant.name, eventName, eventDateTime, referrerInfo ? referrerInfo.name : null);

        console.log('Éxito: Correos enviados exitosamente');
        //toast.add({ severity: 'success', summary: 'Éxito', detail: 'Correos enviados exitosamente', life: 3000 });
      } catch (error) {
        console.error('Error al enviar correos:', error);
        //toast.add({ severity: 'error', summary: 'Error', detail: 'Error al enviar correos', life: 3000 });
      }
    };

    // Función para precargar la página de pagos
    const precachePSEPage = () => {
      const link = document.createElement('link');
      link.rel = 'prefetch';
      link.href = 'https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=1452';
      document.head.appendChild(link);
    };

    const showSuccessDialog = ref(false);

    const closeSuccessDialog = () => {
      showSuccessDialog.value = false;
    };

    const isSubmitting = ref(false);

    const submitAllForms = async () => {
      if (!validateForm()) {
        return;
      }

      isSubmitting.value = true;

      try {
        let allFormsSubmittedSuccessfully = true;
        const selectedEventData = events.value.find(event => event.code === selectedEventCode.value);
        
        // Obtener información del referido una sola vez si es necesario
        const referrerInfo = await getReferrerInfo(referrerCode.value);

        for (const assistant of assistantData.value) {
          try {
            const formData = {
              eventCode: selectedEventCode.value,
              ...assistant,
              ...billingData.value,
              acceptPolicies: acceptPolicies.value
            };

            const success = await submitForm(formData);
            if (!success) {
              allFormsSubmittedSuccessfully = false;
              break;
            }

            // Enviar correos
            await sendEmails(assistant, selectedEventData.name, selectedEventData.dateTime, referrerInfo);

          } catch (error) {
            console.error('Error processing assistant:', error);
            allFormsSubmittedSuccessfully = false;
            toast.add({ severity: 'error', summary: 'Error', detail: `Error al procesar asistente: ${assistant.name}`, life: 3000 });
            break;
          }
        }

        if (allFormsSubmittedSuccessfully) {
          showSuccessDialog.value = true;
          toast.add({ severity: 'success', summary: 'Éxito', detail: 'Inscripción exitosa.', life: 3000 });
          
          // Redirigir a la página de PSE Pagos
          //window.location.href = 'https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=1452';
          window.open('https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=1452', '_blank');

          // Nota: El código siguiente no se ejecutará debido a la redirección
          // Pero lo dejamos por si en el futuro decides cambiar la lógica
          // Es para limpiar el formulario.
          selectedEventCode.value = '';
          assistantData.value = [{
            docId: '',
            name: '',
            email: '',
            city: '',
            cellPhone: ''
          }];
          Object.keys(billingData.value).forEach(key => {
            billingData.value[key] = '';
          });
          billingData.value.billingDocType = 'CC';
          acceptPolicies.value = false;
        }
      } catch (error) {
        console.error('Error general al procesar el formulario:', error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Ocurrió un error al procesar la inscripción', life: 3000 });
      } finally {
        isSubmitting.value = false;
      }
    };
  
    return {
      selectedEventCode,
      assistantData,
      billingData,
      events,
      addAssistantForm,
      copyToInvoice,
      cancelAssistant,
      showSuccessDialog,
      closeSuccessDialog,
      submitAllForms,
      sendEmails,
      acceptPolicies,
      referrerCode,
      isSubmitting,
      attendeeStore
    };
  }
};
</script>
  
  
  
<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

.form-control {
  width: 100%;
  padding: 7px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
}

/* Estilo común para boton inscribirse */
.btn-register {
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Añadir margen superior */
  margin-bottom: 30px; /* Añadir margen inferior */
  align-items: center;
  justify-content: center;
  height: 48px; /* Ajustar según la altura del botón */
}


.btn-primary {
  background-color: #3568d4;
  border: none;
  color: white;
  
}

.btn-primary:hover {
  background-color: #4089b3;
}

.pi-spinner {
  font-size: 1.5em;
  margin-right: 0.5em;
}

/* Estilo para el botón deshabilitado */
.btn-register:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
  
}


.btn-secondary {
  background-color: #498a76;
  border: none;
  color: white;
  padding: 7px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-danger {
  background-color: #dc3545;
  border: none;
  color: white;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.btn-danger:hover {
  background-color: #c82333;
}

.btn-info {
  background-color: #17a2b8;
  border: none;
  color: white;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.btn-info:hover {
  background-color: #138496;
}

.mr-2 {
  margin-right: 0.5rem;
}
.form-check {
  text-align: left;
}

/* Nuevas clases añadidas para el posicionamiento de los botones de radio */
.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.mr-2 {
  margin-right: 0.5rem;
}

/* Estilos adicionales para mejorar la apariencia de los botones de radio */
input[type="radio"] {
  margin-right: 5px;
}

label.form-check-label {
  margin-right: 15px;
}

/* Ajuste para alinear mejor los botones de radio con el texto */
.form-group label.form-label {
  margin-bottom: 0;
}

.referido-label {
  background-color: #f8f9fa;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
  color: #6c757d;
  display: inline-block;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

/* Estilos para el diálogo */
::v-deep(.p-dialog-header) {
  background-color: #4CAF50;
  color: white;
}

::v-deep(.p-dialog-content) {
  padding: 20px;
  text-align: center;
  font-size: 1.2em;
}

/* Estilos para el botón OK en el diálogo */
::v-deep(.p-dialog-footer) {
  text-align: center;
  padding-top: 1rem;
}

::v-deep(.p-button) {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

::v-deep(.p-button:hover) {
  background-color: #45a049;
}

</style>