<template>
  <div class="attendees-container">
    <h2 class="attendees-title">Lista de Asistentes</h2>
    <div class="card attendees-card">
      <div class="table-container" ref="tableContainer">
        <button @click="scrollLeft" class="scroll-button left">&lt;</button>
        <DataTable 
                ref="dt" 
                v-model:filters="filters" 
                v-model:selection="selectedAttendee" 
                :value="attendees"
                stateStorage="session" 
                stateKey="attendees-table" 
                :paginator="true" 
                :rows="rows" :rowsPerPageOptions="[5, 10, 20, 50]"
                filterDisplay="menu" selectionMode="single"
                dataKey="id" 
                :globalFilterFields="['eventCode', 'eventName', 'referrerCode', 'docId', 'name', 'email', 'city', 'cellPhone', 'billingDocType', 'billingDoc', 'billingName', 'billingEmail', 'billingCity', 'billingCellPhone', 'billingPhone', 'billingAddress', 'billingContact', 'observations']"
                
               >
          <template #header>
            <AttendeeTableHeader 
              :columns="columns" 
              :filters="filters"
              :data="attendees"
              v-model:visibleColumns="visibleColumns"
              @export="exportCSV"
            />
          </template>
          
          <Column v-for="col in visibleColumns" :key="col.field" 
                  :field="col.field" :header="col.header" sortable :style="col.style"
                  :frozen="col.frozen" :alignFrozen="col.alignFrozen">
            <template #filter="{ filterModel }" v-if="col.field !== 'acceptPolicies'">
              <InputText v-model="filterModel.value" type="text" :placeholder="`Buscar por ${col.header.toLowerCase()}`" class="p-column-filter" />
            </template>
            <template #body="{ data }" v-if="['acceptPolicies', 'selfRetainerIVA', 'selfRetainerREN', 'selfRetainerICA', 'bigContributor'].includes(col.field)">
                <Tag :value="data[col.field] ? 'Sí' : 'No'" :severity="data[col.field] ? 'success' : 'danger'" />
            </template>
            <template #filter="{ filterModel }" v-if="col.field === 'acceptPolicies'">
              <Dropdown v-model="filterModel.value" :options="['Sí', 'No']" placeholder="Seleccionar" class="p-column-filter" style="min-width: 12rem">
                <template #option="slotProps">
                  <Tag :value="slotProps.option" :severity="slotProps.option === 'Sí' ? 'success' : 'danger'" />
                </template>
              </Dropdown>
            </template>
          </Column>
          
          <template #empty> No se encontraron asistentes. </template>
        </DataTable>
        <button @click="scrollRight" class="scroll-button right">&gt;</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAttendeeStore } from '@/stores/attendeeStore';
import { ref, onMounted, computed, onUnmounted } from 'vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import AttendeeTableHeader from './AttendeeTableHeader.vue';
import Hammer from 'hammerjs';
import { useToast } from 'primevue/usetoast';

const attendeeStore = useAttendeeStore();
const dt = ref(); // Referencia al DataTable
const tableContainer = ref(null);
const toast = useToast();
const selectedAttendee = ref(null);
const rows = ref(10);
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  eventCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  eventName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  referrerCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  docId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  city: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  cellPhone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingDocType: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingDoc: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingEmail: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingCity: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingCellPhone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingPhone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingAddress: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  billingContact: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  acceptPolicies: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  selfRetainerIVA: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  selfRetainerREN: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  selfRetainerICA: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  bigContributor: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  observations: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
  createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  updatedAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
});

const columns = ref([
  { field: 'eventCode', header: 'C.Costos', style: 'width: 150px' },
  { field: 'eventName', header: 'Evento', style: 'width: 200px' },
  { field: 'referrerCode', header: 'Aliado', style: 'width: 150px' },
  { field: 'docId', header: 'Documento', style: 'width: 150px' },
  { field: 'name', header: 'Nombre', style: 'width: 200px' },
  { field: 'email', header: 'Email', style: 'width: 200px' },
  { field: 'city', header: 'Ciudad', style: 'width: 150px' },
  { field: 'cellPhone', header: 'Celular', style: 'width: 150px' },
  { field: 'billingDocType', header: 'CC-NIT', style: 'width: 150px' },
  { field: 'billingDoc', header: 'F.Documento', style: 'width: 150px' },
  { field: 'billingName', header: 'F.Nombre', style: 'width: 200px' },
  { field: 'billingEmail', header: 'F.Email', style: 'width: 200px' },
  { field: 'billingCity', header: 'F.Ciudad', style: 'width: 150px' },
  { field: 'billingCellPhone', header: 'F.Celular', style: 'width: 150px' },
  { field: 'billingPhone', header: 'F.Teléfono', style: 'width: 150px' },
  { field: 'billingAddress', header: 'F.Dirección', style: 'width: 200px' },
  { field: 'billingContact', header: 'F.Contacto', style: 'width: 200px' },
  { field: 'acceptPolicies', header: 'Aceptó Políticas', style: 'width: 150px' },
  { field: 'selfRetainerIVA', header: 'Auto IVA', style: 'width: 150px' },
  { field: 'selfRetainerREN', header: 'Auto REN', style: 'width: 150px' },
  { field: 'selfRetainerICA', header: 'Auto ICA', style: 'width: 150px' },
  { field: 'bigContributor', header: 'Gran Contribuyente', style: 'width: 150px' },
  { field: 'observations', header: 'Observaciones', style: 'width: 200px' },
  { field: 'updatedAt', header: 'Actualización', style: 'width: 150px' },
  { field: 'createdAt', header: 'Fecha Registro', style: 'min-width: 150px', frozen: true, alignFrozen: 'right' }
]);

const visibleColumns = ref(columns.value);


const handleNewRegistration = (event) => {
  const newAttendee = event.detail;
  toast.add({
    severity: 'info',
    summary: 'Nuevo Registro',
    detail: `${newAttendee.name} se ha registrado para ${newAttendee.eventName}`,
    life: 5000
  });
};

// Usar comlputed para obtener los asistentes del store
const attendees = computed(() => attendeeStore.attendees);

onMounted(() => {
  attendeeStore.fetchAttendees();
  window.addEventListener('newRegistration', handleNewRegistration);

  // Configurar Hammer.js para el desplazamiento táctil
  if (tableContainer.value) {
    const hammer = new Hammer(tableContainer.value);
    hammer.on('panleft panright', (ev) => {
      if (ev.type === 'panleft') {
        tableContainer.value.scrollLeft += 10;
      } else if (ev.type === 'panright') {
        tableContainer.value.scrollLeft -= 10;
      }
    });
  }
  
});

onUnmounted(() => {
  window.removeEventListener('newRegistration', handleNewRegistration);
});

const exportCSV = () => {
  dt.value.exportCSV();
};

const scrollAmount = 400; // Ajusta este valor según lo que consideres apropiado

const scrollLeft = () => {
  if (dt.value) {
    const wrapper = dt.value.$el.querySelector('.p-datatable-wrapper');
    if (wrapper) {
      wrapper.scrollLeft = Math.max(wrapper.scrollLeft - scrollAmount, 0);
    }
  }
};

const scrollRight = () => {
  if (dt.value) {
    const wrapper = dt.value.$el.querySelector('.p-datatable-wrapper');
    if (wrapper) {
      const maxScroll = wrapper.scrollWidth - wrapper.clientWidth;
      wrapper.scrollLeft = Math.min(wrapper.scrollLeft + scrollAmount, maxScroll);
    }
  }
};
</script>
  
<style scoped>
.card {
  background: #d2dbf7;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12);
  margin-bottom: 2rem;
}

:deep(.p-datatable .p-datatable-header) {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem;
  font-weight: 600;
}

:deep(.p-datatable .p-datatable-thead > tr > th) {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem;
  font-weight: 600;
}

:deep(.p-datatable .p-datatable-tbody > tr) {
  background: #ffffff;
  transition: box-shadow 0.2s;
}

:deep(.p-datatable .p-datatable-tbody > tr > td) {
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem;
}

.attendees-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.table-container {
  position: relative;
  overflow-x: auto;
  min-width: 300px;
  max-width: 100%;
}

:deep(.p-datatable) {
  min-width: 700px; /* Ajusta minimo del del ancho datatable */
}

:deep(.p-datatable-wrapper) {
  overflow-x: auto;
}

:deep(.p-datatable .p-datatable-tbody > tr > td.p-frozen-column) {
  background-color: #f8f9fa;
  box-shadow: -2px 0 3px rgba(0,0,0,0.1);
  position: sticky !important;
  right: 0;
  z-index: 1;
}

:deep(.p-datatable .p-datatable-thead > tr > th.p-frozen-column) {
  background-color: #e9ecef;
  box-shadow: -2px 0 3px rgba(0,0,0,0.1);
  position: sticky !important;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .attendees-container {
    padding: 0 10px;
  }

  :deep(.p-datatable .p-datatable-thead > tr > th),
  :deep(.p-datatable .p-datatable-tbody > tr > td) {
    padding: 0.5rem;
  }
}

/* Nuevos estilos para los botones de desplazamiento */
.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

/* Asegúrate de que la tabla no tenga padding horizontal */
:deep(.p-datatable-wrapper) {
  padding-left: 0;
  padding-right: 0;
}
/* Fila seleccionada en la tabla  */
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  user-select: text;
}

:deep(.p-datatable .p-datatable-tbody > tr:hover) {
  background-color: #e9ecef;
  cursor: pointer;
}

</style>