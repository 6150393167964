import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

//PrimeVue
import PrimeVue from 'primevue/config'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import Tag from 'primevue/tag'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import ConfirmDialog from 'primevue/confirmdialog'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
//import Tooltip from 'primevue/tooltip'

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'  

// Importa los estilos del Editor (Quill)
import Editor from 'primevue/editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'


const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.use(router)
app.use(PrimeVue)
app.use(ToastService)
app.use(ConfirmationService)

app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('InputText', InputText)
app.component('Dropdown', Dropdown)
app.component('MultiSelect', MultiSelect)
app.component('Tag', Tag)
app.component('Dialog', Dialog)
app.component('Button', Button)
app.component('ConfirmDialog', ConfirmDialog)
app.component('Editor', Editor)
app.component('Checkbox', Checkbox)
app.component('Chip', Chip)
//app.component('Tooltip', Tooltip)

//Sirve para ver cual es la variable de entonor que esta tomando.
console.log('Environment:', process.env.VUE_APP_ENV);

app.mount('#app')