
<template>
    <div>
      <h1>404 - Página No Encontrada</h1>
      <p>La página que estás buscando no existe.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  }
  </script>
  